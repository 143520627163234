<template>
  <div>
    <v-menu
      ref="datePickerMenu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="value"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          autocomplete="off"
          :disabled="disabled"
          v-on="on"
          :id="id"
          :label="label"
          v-bind="attrs"
          v-model="dateRangeText"
          class="datepicker-input"
          hide-details="auto"
          append-icon="mdi-calendar-outline"
          readonly
          dense
          outlined
          clearable
        >
        </v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        locale="pt-br"
        no-title
        scrollable
        range
        :allowed-dates="allowedDates ? allowedDates : null"
        @change="onChange"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="btn-ag-secondary" @click="menu = false"> Cancelar </v-btn>
        <v-btn text class="btn-ag-primary" @click="$refs.datePickerMenu.save(value)"> Confirmar </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'AGDatePickerRange',

  props: {
    allowedDates: {
      type: Function,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'day',
    },
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateRangeText: {
      get: function () {
        let datesComputed = [];
        if (this.dates.length > 0) {
          this.dates.forEach((val, index) => {
            datesComputed[index] = this.formatDate(val);
          });
        }
        return datesComputed.sort().join(' - ');
      },
      set: function (value) {
        if ([null, undefined].includes(value)) {
          value = [];
        }
        this.$emit('input', value);
      },
    },
    dates: {
      get: function () {
        return this.value.sort();
      },
      set: function (value) {
        this.$emit('change', value.sort());
        this.$emit('input', value.sort());
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    onChange() {
      this.$emit('onUpdate');
    }
  },
};
</script>

<style lang="scss" scoped>
@import './src/design/components/datepicker.scss';
</style>
